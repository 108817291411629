import React, { useEffect } from "react"
// import service from "../../../../asserts/services.jpg"
import SLoader from "../../../../asserts/skeleton.gif"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import service3 from "../../../../asserts/services/service3.png"

gsap.registerPlugin(ScrollTrigger)

const ServiceSec = () => {
  const navigate = useNavigate()
  var tl = gsap.timeline()

  useEffect(() => {
    gsap.to(".first", {
      scrollTrigger: {
        trigger: ".first",
        start: "top 50%", // Adjust the trigger start position
        end: "bottom 100%", // Adjust the trigger end position
        scrub: 5, // Enables smooth scrolling effect
        scroller: "body",
      },
      width: "0%",
      duration: 4,
    })
    gsap.to(".second", {
      scrollTrigger: {
        trigger: ".second",
        start: "top 60%", // Adjust the trigger start position
        end: "bottom 100%", // Adjust the trigger end position
        scrub: 3, // Enables smooth scrolling effect
        scroller: "body",
      },
      width: "0%",
    })
  }, [])

  return (
    <React.Fragment>
      <div className="border-2 border-primary">
        <div className="grid grid-rows-2 md:grid-rows-none md:grid-cols-2 grid-flow-col md:grid-flow-row">
          <div className="relative order-2 md:order-none">
          {/* <img src={SLoader} className="w-[50rem] h-[26.5rem] object-cover -z-10 absolute" alt="" /> */}
            <img
              src={service3}
              alt="services"
              className="object-cover h-full w-full"
              loading="lazy"
            />
            <div className="bg-primary absolute inset-0 second"></div>
            <div className="bg-primary bg-opacity-70 absolute inset-0 first"></div>
          </div>
          <div className="h-full order-1 md:order-none flex flex-col items-center flex-1 justify-center sm:py-5 py-10">
            <span className="text-4xl font-bold -pl-[10px]">Our Services</span>
            <p className="sm:py-5 py-10 font-semibold">
              Custom homes
              <br /> Renovation
              <br /> Knock-down rebuild
              <br /> House and Land packages
              <br />
              Planning Permit <br />
              Expertise in Civil engineer
            </p>
            <button
              className="bg-primary py-3 px-9 text-black font-semibold hover:shadow-lg hover:shadow-secondary"
              onClick={() => {
                navigate("/services")
              }}
            >
              MORE INFO
            </button>
          </div>
        </div>
      </div>

      <div className="md:h-72 grid grid-rows-4 md:grid-rows-none md:grid-cols-4 grid-flow-col md:grid-flow-row font-semibold">
        <div className="flex flex-col justify-center text-center items-center border-x-2 border-b-2 py-5 border-primary">
          <svg
            className="w-12 h-12 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
          <span>
            Vast <br /> Experience
          </span>
        </div>
        <div className="flex flex-col justify-center text-center items-center border-x-2 border-b-2 py-5 border-primary">
          <svg
            className="w-12 h-12 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z"
            />
          </svg>
          <span>
            Professional <br /> Team
          </span>
        </div>
        <div className="flex flex-col justify-center text-center items-center border-x-2 border-b-2 py-5 border-primary">
          <svg
            className="w-12 h-12 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 72 72"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              d="M59.45 0H0.949951C0.751039 0 0.560273 0.0790178 0.419621 0.21967C0.278969 0.360322 0.199951 0.551088 0.199951 0.75V59.25C0.199951 59.4489 0.278969 59.6397 0.419621 59.7803C0.560273 59.921 0.751039 60 0.949951 60H59.45C59.6489 60 59.8396 59.921 59.9803 59.7803C60.1209 59.6397 60.2 59.4489 60.2 59.25V0.75C60.2 0.551088 60.1209 0.360322 59.9803 0.21967C59.8396 0.0790178 59.6489 0 59.45 0ZM58.7 28.845H40.4225V1.5H58.7V28.845ZM21.065 16.29H38.9225V43.8525H21.065V16.29ZM38.9225 14.79H21.065V1.5H38.9225V14.79ZM21.065 45.3525H38.9225V58.5H21.065V45.3525ZM19.565 1.5V15.3375C19.5394 15.4022 19.5242 15.4706 19.52 15.54C19.5233 15.6094 19.5387 15.678 19.565 15.7425V28.845H1.69995V1.5H19.565ZM1.69995 30.345H19.565V44.4C19.5355 44.4634 19.5202 44.5326 19.5202 44.6025C19.5202 44.6725 19.5355 44.7416 19.565 44.805V58.5H1.69995V30.345ZM40.4225 58.5V30.345H58.7V58.5H40.4225Z"
              fill="#9A8A78"
            />
          </svg>
          <span>
            High
            <br /> Finish
          </span>
        </div>
        <div className="flex flex-col justify-center text-center items-center border-x-2 border-b-2 py-5 border-primary">
          <svg
            className="w-12 h-12 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
            />
          </svg>
          <span>
            Sustainable <br />& Accountable
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ServiceSec
