import { useEffect } from "react"
import { useLocation } from "react-router-dom"

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === "/contact-us") {
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname])
  return null
}

export default ScrollToTop
