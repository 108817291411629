import React, { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"
import Navbar from "../layout/header/Navbar"
import Transition from "../screen/Transition"
import Home from "../pages/homePage/Home"
import Services from "../pages/servicePage/Services"
import Projects from "../pages/projectPage/Projects"
import About from "../pages/aboutPage/About"
import ConractUs from "../pages/contactUs/contactUs"
import SocialMedia from "../screen/SocialMedia"
import ScrollToTop from "../screen/ScrollTo"
import Loader from "../screen/Loader"
import Footer from "../layout/footer/Footer"
import PrivacyPolicy from "../pages/otherPages/PrivacyPolicy"
import TermAndConditions from "../pages/otherPages/TermAndConditions"
import ListProperties from "../pages/forSalePage/ListProperties"

const Router = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 8000)
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Transition />
      <ScrollToTop />
      <SocialMedia />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route path="/forsale" element={<ListProperties />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="/contact-us" element={<Home />}></Route>
        <Route path="/terms-and-conditions" element={<TermAndConditions />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      </Routes>
      <ConractUs />
      <Footer/>
    </React.Fragment>
  )
}

export default Router
