import React, { useEffect, useRef } from "react"
import gsap, { Expo } from "gsap"
import { leadSection } from "./imageConfig"
import ServiceSec from "./sections/ServiceSec"
import AboutUsSec from "./sections/AboutUsSec"
import RecentProjectSec from "./sections/RecentProjectSec"
import TestimonialSec from "./sections/TestimonialSec"
import { Helmet } from "react-helmet"

const Home = () => {
  var tl = gsap.timeline({
    repeat: -1,
  })

  useEffect(() => {
    tl.to(
      ".hero_image",
      {
        width: "100%",
        ease: Expo.easeInOut,
        duration: 5,
        stagger: 5,
      },
      "hero_section"
    )
      .to(
        ".heroText",
        {
          top: "0%",
          ease: Expo.easeInOut,
          // delay: 0.5,
          duration: 6,
          stagger: 5,
        },
        "hero_section"
      )
      .to(
        ".heroText",
        {
          top: "-110%",
          ease: Expo.easeInOut,
          delay: 4,
          duration: 5,
          stagger: 5,
        },
        "hero_section"
      )
      .to(
        ".hero_bar",
        {
          width: "100%",
          ease: Expo.easeInOut,
          duration: 3,
        },
        "hero_section"
      )
      .to(
        ".hero_image",
        {
          height: "0%",
          ease: Expo.easeInOut,
          duration: 2,
        },
        "collapse"
      )
      .to(
        ".hero_bar",
        {
          width: "0%",
          autoAlpha: 0,
          ease: Expo.easeInOut,
          duration: 2,
        },
        "collapse"
      )
  }, [])

  const handleMouseEnter = () => {
    // Pause the GSAP animation
    // console.log("here");
    tl.pause()
  }

  const handleMouseLeave = () => {
    // resume the GSAP animation
    tl.play()
  }

  return (
    <React.Fragment>
      {/* <Transition /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        <meta
          name="description"
          content="Mann Homes Masters In Building Homes With Quality. A best home builder in australia"
        />
      </Helmet>
      <div className="relative md:h-[720px] h-[480px] w-full border-b-2 border-x-2 border-gray-800">
        <div className="h-full w-full relative overflow-hidden">
          {leadSection &&
            leadSection.map((items, index) => {
              return (
                <div key={"heroimages" + index}>
                  <div className="w-full h-full absolute">
                    <img
                      src={items.imageSrc ? items.imageSrc : ""}
                      alt="object"
                      className="object-cover h-full w-0 hero_image"
                    />
                  </div>
                  <div className="absolute bg-transparent w-full h-full top-0 flex justify-center items-center">
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="w- full md:h-[84px] h-[115px] text-justify flex justify-center relative overflow-hidden bg-primary bg-opacity-30 shadow-xl hero_bar"
                    >
                      <div className="flex items-center text-center px-2 md:px-0 text-white flex-col absolute top-[120px] z-10 hover:cursor-default heroText">
                        <span className="md:text-2xl text-lg md:font-bold font-semibold">
                          {items.text && items.text.mainText}
                        </span>
                        <span className="font-medium">
                          {items.text && items.text.subText}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <div className="md:py-20 md:px-10">
        <ServiceSec />
        <AboutUsSec />
        <RecentProjectSec />
        <TestimonialSec />
      </div>
    </React.Fragment>
  )
}

export default Home
