import React, { useEffect, useRef, useState } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import Logo from "../../../asserts/logo512-removedbg.png"
import { mainMenu } from "../navigationConfig"
import gsap, { Expo } from "gsap"

const Navbar = () => {
  const navigate = useNavigate()
  var tl = gsap.timeline()
  const [menuState, setMenuState] = useState(false)

  function handleRoute(e) {
    navigate(e)
    if (menuState) {
      handleOpen()
    }
  }

  useEffect(() => {
    if (menuState) {
      tl.to(
        ".burggerMenu",
        {
          height: "110%",
          // bottom: "0",
          // top: "0",
          ease: Expo.easeInOut,
          duration: 2,
        },
        "flag_name"
      )
    } else {
      tl.to(
        ".burggerMenu",
        {
          height: "0%",
          // bottom: "50rem",
          // top: "-11rem",
          ease: Expo.easeInOut,
          duration: 1,
        },
        "flag_"
      )
    }
  }, [menuState])

  const handleOpen = () => {
    setMenuState(!menuState)
  }

  return (
    <React.Fragment>
      <div className="bg-primary  hidden md:block fixed inset-x-0 z-20 border-b-2 border-black px-9 space-x-10 text-white">
        <a href="tel:+610434055559" target="_blank" rel="noopener noreferrer">
          +61 0434 055 559
        </a>
        <a
          href="mailto:info@mannhomes.com.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@mannhomes.com.au
        </a>
      </div>
      <div className="h-[90px] md:mt-[25px] z-20 fixed inset-x-0 shadow-md bg-primary bg-opacity- 20 shadow-gray-900 backdrop-blur-sm md:flex hidden items-center px-4">
        <div className="flex justify-between w-full">
          <div
            className="flex justify-center items-center hover:cursor-pointer"
            onClick={() => {
              navigate("/")
            }}
          >
            <img
              src={Logo}
              alt=""
              className="w-[8rem] p-1 bg-white rounded-2xl"
            />
          </div>
          <div className="flex items-center space-x-3">
            {mainMenu.map((data, key) => {
              return (
                <div className="" key={"adminMenu" + key}>
                  <button className="py-3 text-center w-28 text-lg hover:font-semibold hover:text-white font-medium">
                    <span
                      className="flex justify-center"
                      onClick={() => {
                        handleRoute(data.route)
                      }}
                    >
                      {data.name}
                    </span>
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="h-[80px] z-20 fixed inset-x-0 shadow-md shadow-gray-900 backdrop-blur-sm flex md:hidden items-center px-4">
        <div className="flex justify-between w-full">
          <div className="flex justify-center items-center">
            <img src={Logo} alt="" className="w-20" />
          </div>
          <div className="flex items-center">
            <svg
              onClick={() => {
                handleOpen()
              }}
              className="w-7 h-7"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* {BurggermenuState && ( */}
      <div className="fixed inset-0 h-0 z-10 backdrop-blur-sm burggerMenu flex justify-end overflow-hidden">
        <div className="bg-white h-full border-l-2 border-secondary w-10/12">
          <div className="flex justify-end mr-5 mt-5">
            <svg
              onClick={() => {
                handleOpen()
              }}
              className="w-8 h-8 hover:cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth="2.0"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="flex flex-col h-full items-center justify-center -mt-14">
            {mainMenu.map((data, key) => {
              return (
                <div className="" key={"adminMenu" + key}>
                  <button className="py-4 text-center w-28 font-medium">
                    <span
                      className="flex justify-center underline underline-offset-4"
                      onClick={() => {
                        handleRoute(data.route)
                      }}
                    >
                      {data.name}
                    </span>
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* )} */}
    </React.Fragment>
  )
}

export default Navbar
