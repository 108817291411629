export const mainMenu = [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "Services",
      route: "/services",
    },
    {
      name: "Projects",
      route: "/projects",
    },
    {
      name: "About Us",
      route: "/about-us",
    },
    {
      name: "Contact Us",
      route: "/contact-us",
    },
    // {
    //   name: "FAQ",
    //   route: "/faq",
    // //   subMenu: [
    // //     {
    // //       name: "name",
    // //       route: "",
    // //     },
    // //   ],
    // },
  ]