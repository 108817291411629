import React, { useEffect, useState } from "react"
import Routers from "./components/router/Router"
import Loader from "./components/screen/Loader"
import Transition from "./components/screen/Transition"
import gsap from "gsap"
import { Helmet } from "react-helmet"

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const home = gsap.timeline()

  useEffect(() => {
    // Simulate loading delay for demonstration
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>MANN HOMES</title>
        <meta
          name="description"
          content="Mann Homes - Building Dreams Into Reality"
        />
        <meta
          name="keywords"
          content="construction, home building, Mann Homes"
        />
      </Helmet>
      <div className="h-screen text-black">
        {/* <Transition timeline={home} />  */}
        {/* {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
          </>
        )} */}
        <Routers />
      </div>
    </React.Fragment>
  )
}

export default App
