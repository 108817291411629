import React from "react"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const Footer = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="pb-12 md:px-16 px-5 space-y-6 flex flex-col">
        <div className="flex md:flex-row flex-col font-medium text-lg md:space-x-3 space-y-4 md:space-y-0">
          <span
            className="underline underline-offset-3 hover:cursor-pointer"
            onClick={() => {
              navigate("/contact-us")
            }}
          >
            Contact Us
          </span>
          {/* <span
            className="underline underline-offset-3 hover:cursor-pointer"
            onClick={() => {
              navigate("/privacy-policy")
            }}
          >
            Privacy policy
          </span> */}
          {/* <span
            className="underline underline-offset-3 hover:cursor-pointer"
            onClick={() => {
              navigate("/terms-and-conditions")
            }}
          >
            Terms and conditions
          </span> */}
        </div>
        <p className="text-sm">
          ©{moment().format("YYYY")} All Right Reserved by MANN HOMES Pty Ltd. PO Box 2034
          Melton South VIC 3338
          {/* <a
            href="https://www.instagram.com/reimazine/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 underline hover:pointer"
          >
            Reimazine Media
          </a> */}
        </p>
        <p className="md:text-sm text-xs">
          {" "}
          The images displayed on this webpage might showcase enhancements,
          materials, and amenities that are not provided by Mann Homes, such as
          landscaping, fencing, driveway etc. Therefore, the prices listed on this
          webpage do not encompass the provision of these specific items. For
          comprehensive information on home pricing, please consult with a Home
          Advisor. VIC Builder's Licence: CBU-76220.
        </p>
      </div>
    </React.Fragment>
  )
}

export default Footer
