import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const SocialMedia = () => {
  const { pathname } = useLocation()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    if (
      pathname === "/terms-and-conditions" ||
      pathname === "/privacy-policy"
    ) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [pathname])

  return (
    <>
      {isVisible &&
      (
        <div className="fixed left-0 z-20 inset-y-0 flex flex-col justify-center pl-2">
          <div className="flex flex-col space-y-7 border-2 border-primary rounded-2xl">
            <a
              href="https://www.facebook.com/profile.php?id=100088296961280"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-6 h-6"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="40" rx="20" fill="#1877F2" />
                <path
                  d="M27.7852 25.7812L28.6719 20H23.125V16.25C23.125 14.668 23.8984 13.125 26.3828 13.125H28.9062V8.20312C28.9062 8.20312 26.6172 7.8125 24.4297 7.8125C19.8594 7.8125 16.875 10.582 16.875 15.5938V20H11.7969V25.7812H16.875V39.7578C17.8945 39.918 18.9375 40 20 40C21.0625 40 22.1055 39.918 23.125 39.7578V25.7812H27.7852Z"
                  fill="white"
                />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/mann_homes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-6 h-6"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.25 23.5H5.81666C2.97499 23.5 0.649994 21.25 0.649994 18.5V5.5C0.649994 2.75 2.97499 0.5 5.81666 0.5H19.25C22.0917 0.5 24.4167 2.75 24.4167 5.5V18.5C24.4167 21.25 22.0917 23.5 19.25 23.5Z"
                  fill="url(#paint0_radial_0_1)"
                />
                <path
                  d="M12.5333 5C14.91 5 15.1683 5 16.0983 5.05C16.9767 5.1 17.4417 5.25 17.7517 5.35C18.165 5.5 18.475 5.7 18.785 6C19.095 6.3 19.3017 6.6 19.4567 7C19.56 7.3 19.715 7.75 19.7667 8.6C19.7667 9.45 19.7667 9.7 19.7667 12C19.7667 14.3 19.7667 14.55 19.715 15.45C19.6633 16.3 19.5083 16.75 19.405 17.05C19.25 17.45 19.0433 17.75 18.7333 18.05C18.4233 18.35 18.1133 18.55 17.7 18.7C17.39 18.8 16.925 18.95 16.0467 19C15.1683 19 14.91 19 12.5333 19C10.1567 19 9.89833 19 8.96833 18.95C8.09 18.9 7.625 18.75 7.315 18.65C6.90167 18.5 6.59167 18.3 6.28167 18C5.97167 17.7 5.765 17.4 5.61 17C5.50667 16.7 5.35167 16.25 5.3 15.4C5.3 14.55 5.3 14.3 5.3 12C5.3 9.7 5.3 9.45 5.35167 8.55C5.40333 7.7 5.55833 7.25 5.66167 6.95C5.81667 6.55 6.02333 6.25 6.33333 5.95C6.64333 5.65 6.95333 5.45 7.36667 5.3C7.67667 5.2 8.14167 5.05 9.02 5C9.89833 5 10.1567 5 12.5333 5ZM12.5333 3.5C10.1567 3.5 9.84667 3.5 8.91667 3.55C7.98667 3.6 7.36667 3.75 6.79833 3.95C6.23 4.15 5.71333 4.45 5.24833 4.95C4.78333 5.45 4.47333 5.9 4.215 6.45C4.00833 7 3.85333 7.6 3.80167 8.5C3.75 9.4 3.75 9.7 3.75 12C3.75 14.3 3.75 14.6 3.80167 15.5C3.85333 16.4 4.00833 17 4.215 17.55C4.42167 18.1 4.73167 18.6 5.24833 19.05C5.71333 19.5 6.23 19.8 6.79833 20.05C7.36667 20.25 7.98667 20.4 8.91667 20.45C9.84667 20.5 10.1567 20.5 12.5333 20.5C14.91 20.5 15.22 20.5 16.15 20.45C17.08 20.4 17.7 20.25 18.2683 20.05C18.8367 19.85 19.3533 19.55 19.8183 19.05C20.2833 18.6 20.5933 18.1 20.8517 17.55C21.0583 17 21.2133 16.4 21.265 15.5C21.3167 14.6 21.3167 14.3 21.3167 12C21.3167 9.7 21.3167 9.4 21.265 8.5C21.2133 7.6 21.0583 7 20.8517 6.45C20.645 5.9 20.335 5.4 19.8183 4.95C19.3017 4.5 18.8367 4.2 18.2683 3.95C17.7 3.75 17.08 3.6 16.15 3.55C15.22 3.5 14.91 3.5 12.5333 3.5Z"
                  fill="white"
                />
                <path
                  d="M12.5333 7.5C9.95 7.5 7.88333 9.5 7.88333 12C7.88333 14.5 9.95 16.5 12.5333 16.5C15.1167 16.5 17.1833 14.5 17.1833 12C17.1833 9.5 15.1167 7.5 12.5333 7.5ZM12.5333 15C10.8283 15 9.43333 13.65 9.43333 12C9.43333 10.35 10.8283 9 12.5333 9C14.2383 9 15.6333 10.35 15.6333 12C15.6333 13.65 14.2383 15 12.5333 15Z"
                  fill="white"
                />
                <path
                  d="M17.1833 8.5C17.754 8.5 18.2167 8.0523 18.2167 7.5C18.2167 6.9477 17.754 6.5 17.1833 6.5C16.6126 6.5 16.15 6.9477 16.15 7.5C16.15 8.0523 16.6126 8.5 17.1833 8.5Z"
                  fill="white"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_0_1"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(5.56293 28.1478) rotate(-49.0171) scale(35.3081 23.4434)"
                  >
                    <stop offset="0.073" stop-color="#EACC7B" />
                    <stop offset="0.184" stop-color="#ECAA59" />
                    <stop offset="0.307" stop-color="#EF802E" />
                    <stop offset="0.358" stop-color="#EF6D3A" />
                    <stop offset="0.46" stop-color="#F04B50" />
                    <stop offset="0.516" stop-color="#F03E58" />
                    <stop offset="0.689" stop-color="#DB359E" />
                    <stop offset="0.724" stop-color="#CE37A4" />
                    <stop offset="0.789" stop-color="#AC3CB4" />
                    <stop offset="0.877" stop-color="#7544CF" />
                    <stop offset="0.98" stop-color="#2B4FF2" />
                  </radialGradient>
                </defs>
              </svg>
            </a>

            <a
              href="https://www.tiktok.com/@mannhomes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="w-6 h-6"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.653 14.4391C31.2253 16.2844 34.3764 17.3701 37.7798 17.3701V10.7977C37.1357 10.7978 36.4932 10.7304 35.8631 10.5964V15.7699C32.46 15.7699 29.3093 14.6842 26.7363 12.839V26.2515C26.7363 32.961 21.3165 38.3999 14.6311 38.3999C12.1367 38.3999 9.81819 37.643 7.89221 36.3451C10.0904 38.6007 13.1559 40 16.5474 40C23.2332 40 28.6533 34.5612 28.6533 27.8513V14.4391H28.653ZM31.0175 7.80833C29.7029 6.36705 28.8398 4.50447 28.653 2.4453V1.59995H26.8367C27.2939 4.21715 28.8533 6.45313 31.0175 7.80833ZM12.1207 31.1963C11.3862 30.2299 10.9893 29.0477 10.9911 27.8321C10.9911 24.7634 13.4701 22.2752 16.5285 22.2752C17.0985 22.2751 17.6651 22.3627 18.2083 22.5356V15.8163C17.5735 15.729 16.9328 15.6919 16.2924 15.7055V20.9355C15.7488 20.7625 15.182 20.6747 14.6119 20.6752C11.5534 20.6752 9.07457 23.1631 9.07457 26.2322C9.07457 28.4023 10.3137 30.2811 12.1207 31.1963Z"
                  fill="#FF004F"
                />
                <path
                  d="M26.7362 12.8389C29.3092 14.684 32.4599 15.7698 35.863 15.7698V10.5963C33.9634 10.1902 32.2817 9.19401 31.0173 7.80833C28.8531 6.45299 27.2938 4.21701 26.8366 1.59995H22.0656V27.851C22.0548 30.9114 19.58 33.3894 16.5281 33.3894C14.7297 33.3894 13.132 32.529 12.1202 31.1963C10.3133 30.2811 9.07417 28.4022 9.07417 26.2324C9.07417 23.1635 11.553 20.6754 14.6115 20.6754C15.1975 20.6754 15.7622 20.7669 16.292 20.9357V15.7056C9.72414 15.8418 4.44196 21.2275 4.44196 27.8512C4.44196 31.1576 5.75734 34.1551 7.89222 36.3452C9.8182 37.643 12.1367 38.4 14.6312 38.4C21.3166 38.4 26.7364 32.9609 26.7364 26.2515V12.8389H26.7362Z"
                  fill="black"
                />
                <path
                  d="M35.863 10.5963V9.19747C34.15 9.20008 32.4707 8.71865 31.0174 7.80822C32.3039 9.22177 33.998 10.1964 35.863 10.5963ZM26.8366 1.59997C26.793 1.34985 26.7595 1.09808 26.7363 0.845356V0H20.1488V26.2514C20.1383 29.3113 17.6636 31.7893 14.6115 31.7893C13.7154 31.7893 12.8694 31.5758 12.1202 31.1965C13.1321 32.5291 14.7298 33.3892 16.5282 33.3892C19.5798 33.3892 22.055 30.9115 22.0656 27.8512V1.59997H26.8366ZM16.2923 15.7057V14.2165C15.7419 14.141 15.187 14.1031 14.6313 14.1034C7.94531 14.1032 2.52545 19.5423 2.52545 26.2514C2.52545 30.4575 4.65555 34.1645 7.8924 36.345C5.75751 34.155 4.44213 31.1574 4.44213 27.851C4.44213 21.2275 9.72417 15.8419 16.2923 15.7057Z"
                  fill="#00F2EA"
                />
              </svg>
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default SocialMedia
