import React from "react"
import { mainMenu } from "../../layout/navigationConfig"
import { useNavigate } from "react-router-dom"

const ConractUs = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="flex justify-between flex-col md:flex-row md:px-20 px-2 space-y-10 md:space-y-0 border-t-2 border-primary py-10">
        <div className="flex-1 space-y-9 mt-14 md:mt-0">
          <span className="text-4xl font-semibold">Contact Us</span>
          <div className="flex space-x-5 text-lg">
            <svg
              className="w-10 h-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
              />
            </svg>
            <span>
              PO Box 2034
              <br /> Melton South VIC 3338
            </span>
          </div>
          <div className="flex space-x-5 text-lg">
            <svg
              className="w-10 h-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
            <a
              href="mailto:info@mannhomes.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@mannhomes.com.au
            </a>
          </div>
          <div className="flex space-x-5 text-lg">
            <svg
              className="w-10 h-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              />
            </svg>
            <a
              href="tel:+610434055559"
              target="_blank"
              rel="noopener noreferrer"
            >
              +61 0434 055 559
            </a>
          </div>
        </div>

        <div className="flex-1 flex flex-col items-center space-y-9">
          <div className="flex md:space-x-5 space-y-5 md:space-y-0 md:flex-row flex-col w-full">
            <input
              type="text"
              className="bg-transparent border-b-2 border-primary px-6 py-4 focus:outline-none"
              placeholder="First Name *"
              required
            />
            <input
              type="text"
              className="bg-transparent border-b-2 border-primary px-6 py-4 focus:outline-none"
              placeholder="Last Name"
            />
          </div>
          <input
            type="email"
            className="bg-transparent border-b-2 border-primary px-6 py-4 w-full focus:outline-none"
            placeholder="Email *"
            required
          />
          <div className="flex md:space-x-5 space-y-5 md:space-y-0 md:flex-row flex-col w-full">
            <input
              type="number"
              className="bg-transparent border-b-2 border-primary px-6 py-4 focus:outline-none"
              placeholder="Phone *"
              required
            />
            <input
              type="text"
              className="bg-transparent border-b-2 border-primary px-6 py-4 focus:outline-none"
              placeholder="Address"
            />
          </div>
          <button className="border-2 border-primary w-full py-3 hover:bg-primary hover:text-black font-semibold">
            Submit
          </button>
        </div>

        <div className="flex-1 flex flex-col md:items-end items-center space-y-3">
          {mainMenu.map((items, index) => {
            return (
              <div
                key={"navmenus" + index}
                className="text-lg hover:cursor-pointer hover:underline hover:underline-offset-2"
                onClick={() => {
                  navigate(items.route)
                }}
              >
                {items.name}
              </div>
            )
          })}
          <button
            className="bg-primary text-black h-12 px-7 text-lg hover:bg-transparent hover:text-primary hover:border-2 hover:border-primary"
            onClick={() => {
              window.open("tel:+610434055559", "_blank")
            }}
          >
            CALL NOW
          </button>
          <div className="pt-5 text-right">
            <span className="text-xl font-semibold">Openning Hours</span>
            <p className="font-medium">
              Monday - Friday: 9AM - 6PM <br /> Saturday - Sunday: By
              Appointment
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ConractUs
