import React from "react"

const PrivacyPolicy = () => {
  return (
    <>
      <div className="pt-36 pb-24 md:px-10 px-4 flex flex-col items-center">
        <span className="font-medium text-4xl">Privacy Policy</span>
        <div className="flex flex-col md:flex-row justify-between w-full mt-20">
          <p className="font-bold md:text-4xl text-xl">Our Privacy Policy</p>
          <div className="md:w-[70%] text-justify mt-9 md:mt-0 md:text-base text-sm">
            <span>
              This Privacy Policy outlines how MANN HOMES Pty Ltd (ABN 93629804360) and its affiliated entities (referred to as
              "MANN HOMES") collect and handle your personal information.
              <br />
              <br />
              MANN HOMES values your privacy and is committed to adhering to the
              Privacy Act 1988 and the Australian Privacy Principles. This
              Privacy Policy is provided without charge.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              Why Does MANN HOMES Collect Personal Information and How is it
              Utilized?
            </span>
            <br />
            <br />
            <span>
              MANN HOMES typically collects your personal information in the
              course of providing its products and services, informing you about
              them, complying with contractual and legal obligations, conducting
              promotions and marketing activities, and managing its relationship
              with you. This includes responding to your inquiries and sharing
              information about MANN HOMES' events, products, and services that
              may be of interest.
              <br />
              <br />
              MANN HOMES requires your personal information to fulfill these
              objectives. Your personal information may be employed for these
              purposes, any other purposes disclosed in a collection statement
              at the point of data collection, or as otherwise communicated
              during the data collection process.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              How Does MANN HOMES Collect Personal Information?
            </span>
            <br />
            <br />
            <span>
              MANN HOMES may gather your information through various channels,
              including in-person interactions with sales and customer service
              personnel, via its websites, social media profiles, smartphone
              applications, telephone calls, and at physical locations using
              paper forms or electronic devices (referred to as "Collection
              Channels").
              <br />
              <br />
              Information may also be collected through Service Provider
              Channels if you provide products and/or services to MANN HOMES.
              This personal information is typically collected to facilitate the
              provision of products and/or services.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              What Types of Personal Information Does MANN HOMES Collect?
            </span>
            <br />
            <br />
            <span>
              MANN HOMES collects the necessary personal information to support
              the provision of its products and services and to keep you
              informed about them. This may include, through Collection
              Channels, personal details such as your name, date of birth, phone
              number, email and residential addresses, as well as information
              used for customer relationship management and market research
              purposes, such as your interests, experiences, and preferences.
              <br />
              <br />
              For specific contractual or financial purposes, MANN HOMES may
              collect loan application details, financial/banking information,
              and guarantor/next of kin contact information through Collection
              Channels.
              <br />
              <br />
              Through Service Provider Channels, MANN HOMES may gather
              information about your employment or trading history and any
              business, company, or related entity you are associated with.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              How Does MANN HOMES Use Your Personal Information and to Whom Is
              It Disclosed?
            </span>
            <br />
            <br />
            <span>
              MANN HOMES may use your personal information for the primary
              purpose it was collected, as specified in a privacy collection
              statement through a particular Collection Channel or Service
              Provider Channel, or as could reasonably be expected at the time
              of collection. It may also be used for secondary related purposes.
              <br />
              <br />
              By providing your personal information, you consent to MANN HOMES
              using it to:
              <br />
              <br />
              a) Provide products or services to you;
              <br />
              <br />
              b) Complete activities you have chosen to undertake;
              <br />
              <br />
              c) Manage MANN HOMES' relationship with you;
              <br />
              <br />
              d) Monitor online activity on MANN HOMES' website(s) and/or
              application(s);
              <br />
              <br />
              e) Enhance and expand MANN HOMES' products and services (including
              online);
              <br />
              <br />
              f) Respond to serious threats to individuals' lives or public
              health or safety;
              <br />
              <br />
              g) Address suspected unlawful activity; or
              <br />
              <br />
              h) When required or authorized by law.
              <br />
              <br />
              MANN HOMES may share personal information with other entities
              within the MANN HOMES group of companies to achieve the purposes
              mentioned above or as mandated by law.
              <br />
              <br />
              MANN HOMES may also disclose personal information:
              <br />
              <br />
              a) To third parties engaged by MANN HOMES to provide services,
              including IT services like database management, credit card
              processing, mailings, debt collection, market research, and
              advertising; and
              <br />
              <br />
              b) To third parties authorized by you to receive your personal
              information.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              Can You Remain Anonymous or Withhold Personal Information?
            </span>
            <br />
            <br />
            <span>
              Yes, when feasible, you may choose not to disclose your identity,
              interact with us anonymously, or use a pseudonym. However, in some
              cases, failure to provide required personal information may result
              in MANN HOMES being unable to provide requested products or
              services, allow participation in promotional activities, or obtain
              the product or service you are offering. Consequently, MANN HOMES
              may need to discontinue ongoing sales processes, suspend, cancel,
              or renew a contract (subject to its terms and conditions and
              relevant laws), withdraw an invitation or offer, or decline your
              entry into a competition.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              Will You Receive Direct Marketing?
            </span>
            <br />
            <br />
            <span>
              If you provide us with your personal information and consent to
              receiving direct marketing communications from MANN HOMES, you
              authorize us to send you promotional messages and materials
              related to MANN HOMES' products and services unless you later
              request not to receive such communications. MANN HOMES' direct
              marketing communications will include a clear statement (including
              a functional unsubscribe mechanism for electronic messages)
              allowing you to opt out of receiving further direct marketing
              communications. If you prefer not to receive marketing or other
              communications from us, please submit a request to opt out or use
              the unsubscribe feature provided in our most recent communication
              or contact us using the provided details.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              Will Your Information Be Disclosed Overseas?
            </span>
            <br />
            <br />
            <span>
              MANN HOMES is unlikely to disclose your personal information to
              entities outside of Australia. However, we will inform you if and
              when such disclosure becomes necessary.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              How Can You Access and Correct Your Personal Information?
            </span>
            <br />
            <br />
            <span>
              You generally have the right to access your personal information
              at no cost, subject to limitations outlined in the Privacy Act
              1988. The Australian Privacy Principles specify certain
              circumstances in which MANN HOMES may not be required to provide
              access. In such cases, you may be granted access in a manner
              consistent with the Privacy Act 1988.
              <br />
              <br />
              To protect your personal information held by MANN HOMES, identity
              confirmation may be required before granting access. Processing
              your access request and retrieving information from storage (if
              applicable) may take some time.
              <br />
              <br />
              MANN HOMES encourages you to keep your information accurate,
              current, and complete. You may correct any errors or request the
              deletion of some or all of your personal information.
              Additionally, you can request not to receive further contact from
              us.
            </span>
            <br />
            <br />
            <span className="font-medium text-lg">
              Does MANN HOMES Use 'Cookies'?
            </span>
            <br />
            <br />
            <span>
              Yes, when you use MANN HOMES' website(s) and/or application(s), we
              and our IT service providers may collect information using
              technologies like cookies, tags, web beacons, navigational data
              collection (log files, server logs, and clickstream data), and
              other storage technologies. This information may include the date,
              time, and duration of visits, as well as accessed webpages. This
              information is generally not linked to your identity unless
              accessed through links in MANN HOMES e-messages or when you
              voluntarily identify yourself.
              <br />
              <br />
              MANN HOMES employs various third-party analytic programs,
              including Facebook Custom Audience, Facebook Pixel, and Google
              Analytics features related to Display Advertising, to assess the
              performance of our website and mobile applications and to provide
              targeted ads. Information obtained for these purposes is
              aggregated and de-identified. Third parties may display our ads on
            </span>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
