import React from "react"
import { PropertiesList } from "./PropertiesConfig"
import _ from "lodash"

const ListProperties = () => {

  const handleQuote=()=>{}
  return (
    <React.Fragment>
      <div className="pt-36 pb-24 px-10 flex flex-col items-center">
        <h1 className="font-bold text-4xl uppercase">Listed Properties</h1>
        <div className="mt-10 space-y-9 w-full">
          {PropertiesList.map((data, key) => {
            return (
              <div className="flex md:flex-row flex-col md:space-x-10 h-[360px] overflow-hidden border-2 rounded-xl border-primary shadow-lg shadow-secondary">
                <div className="md:w-[40%]">
                  <img
                    src={data.img}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="hidden md:block w-[50%] py-5 md:px-0 px-5 space-y-4">
                  <h1 className="font-medium text-2xl">{data.title}</h1>
                  <p>
                    {_.truncate(data.desc, {
                      length: 400,
                    })}
                  </p>
                  <p>Landmark:</p>
                  <div className="flex flex-wrap">
                    <div className="flex space-x-2 px-4">
                      <p>Hospital:</p>
                      <p>{data.landmark.hospital}</p>
                    </div>
                    <div className="flex space-x-2 px-4">
                      <p>Airport:</p>
                      <p>{data.landmark.airport}</p>
                    </div>
                    <div className="flex space-x-2 px-4">
                      <p>School:</p>
                      <p>{data.landmark.school}</p>
                    </div>
                    <div className="flex space-x-2 px-4">
                      <p>Atm:</p>
                      <p>{data.landmark.atm}</p>
                    </div>
                    <div className="flex space-x-2 px-4">
                      <p>Bank:</p>
                      <p>{data.landmark.bank}</p>
                    </div>
                    <div className="flex space-x-2 px-4">
                      <p>Bus Stop:</p>
                      <p>{data.landmark.bus_stop}</p>
                    </div>
                    <div className="flex space-x-2 px-4">
                      <p>Metro:</p>
                      <p>{data.landmark.metro}</p>
                    </div>
                  </div>
                  <button className="bg-primary py-2 px-6 text-white font-medium rounded-md hover:shadow-lg hover:shadow-secondary">
                    Get A Quote
                  </button>
                </div>
                <div className="py-5 md:px-0 px-5 space-y-4">
                  <h1 className="font-medium text-2xl">{data.title}</h1>
                  <p>
                    {_.truncate(data.desc, {
                      length: 70,
                    })}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ListProperties
