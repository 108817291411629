import React from "react"
import { useNavigate } from "react-router-dom"
import about from "../../../asserts/aboutUs.jpg"

const About = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="flex justify-center w-full pt-16">
        <div className="my-24 flex flex-col items-center">
          <span className="text-4xl font-bold">About Us</span>
          <div className="border-2 border-primary md:mx-28 mx-5 mt-10 flex flex-col md:flex-row justify-center items-center">
            <div className="w- [90%] px-5 py-6 font-medium text-justify border-r-0 border-b-2 md:border-b-0 md:border-r-2 border-primary">
              <p>
                In English
                <br />
                Welcome to Mann Homes - your ultimate destination for
                transforming your dream of a perfect home into reality. From the
                initial stages of planning and permit acquisition, right through
                to the grand moment of completion, we orchestrate every facet of
                the journey. Our distinguished team features accredited civil
                engineers, adept builders, and demolition specialists, all
                working in harmony to bring your vision to life. With a rich
                legacy spanning over a decade in the construction realm, we
                proudly embody a legacy of expertise and distinction in the
                field. Your dream home awaits its masterpiece transformation
                with Mann Homes
              </p>
              <br />
              <p>
                In Punjabi
                <br />
                ਮਾਨ ਹੋਮਜ਼ ਵਿੱਚ ਸੁਆਗਤ ਹੈ - ਤੁਹਾਡੇ ਸਪਨੇ ਨੂੰ ਇੱਕ ਸੰਪੂਰਣ ਘਰ ਦੇ
                ਤੁਹਾਡੇ ਸੁਪਨੇ ਨੂੰ ਹਕੀਕਤ ਵਿੱਚ ਬਦਲਣ ਲਈ ਤੁਹਾਡੀ ਅੰਤਮ ਮੰਜ਼ਿਲ। ਪਲਾਨਿੰਗ
                ਅਤੇ ਪਰਮਿਟ ਪ੍ਰਾਪਤੀ ਦੇ ਸ਼ੁਰੂਆਤੀ ਪੜਾਵਾਂ ਤੋਂ, ਮੁਕੰਮਲ ਹੋਣ ਦੇ ਸ਼ਾਨਦਾਰ
                ਪਲ ਤੱਕ, ਅਸੀਂ ਸਫਰ ਦੀ ਹਰ ਚੋਟੀ ਨੂੰ ਸਨਚਿਤ ਕਰਦੇ ਹਾਂ। ਸਾਡੀ ਵਿਸ਼ੇਸ਼ ਟੀਮ
                ਵਿੱਚ ਮਾਨਤਾ ਪ੍ਰਾਪਤ ਸਿਵਲ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਹਨ - ਸਿਵਲ ਇੰਜੀਨੀਅਰ, ਡਿਜ਼ਾਈਨ
                ਸਪੇਸ਼ੀਅਲਿਸਟ, ਮਾਹਰ ਬਿਲਡਰ ਅਤੇ ਢਾਹੁਣ ਦੇ ਮਾਹਿਰ ਸ਼ਾਮਲ ਹਨ, ਸਾਰੇ ਤੁਹਾਡੇ
                ਦ੍ਰਿਸ਼ਟੀ ਨੂੰ ਜੀਵਨ ਵਿੱਚ ਲਿਆਉਣ ਲਈ ਇਕਸੁਰਤਾ ਵਿੱਚ ਕੰਮ ਕਰਦੇ ਹਨ। ਜਿੱਥੇ
                ਤੁਹਾਡੀ ਦਿਖੀਤਾ ਦ੍ਰਿਸ਼ਟੀ ਨੂੰ ਜੀਵੰਤ ਕਰਨ ਦਾ ਸੰਗੀਤ ਕੀਤਾ ਜਾਂਦਾ ਹੈ।
                ਨਿਰਮਾਣ ਖੇਤਰ ਵਿਚ ਇੱਕ ਦਹਾਕੇ ਤੋਂ ਵੱਧ ਸਮੇਂ ਤੱਕ ਫੈਲੀ ਇੱਕ ਅਮੀਰ ਵਿਰਾਸਤ
                ਦੇ ਨਾਲ, ਅਸੀਂ ਮਾਣ ਨਾਲ ਇਸ ਖੇਤਰ ਵਿੱਚ ਮੁਹਾਰਤ ਅਤੇ ਵਿਲੱਖਣਤਾ ਦੀ ਵਿਰਾਸਤ
                ਨੂੰ ਮੂਰਤੀਮਾਨ ਕਰਦੇ ਹਾਂ। ਤੁਹਾਡਾ ਸੁਪਨਿਆਂ ਦਾ ਘਰ ਦੇ ਮਾਸਟਰਪੀਸ ਵਿੱਚ 
                ਤਬਦੀਲੀ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹੈ, ਮਾਨ ਹੋਮਜ਼ ਦੇ ਨਾਲ।
              </p>
            </div>
            <img
              src={about}
              className="w-[30rem] h-full"
              alt=""
              loading="lazy"
              onClick={() => {
                navigate("/")
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default About
