import React from "react"
import logo from "../../asserts/logo512-removedbg.png"
import gsap, { Expo } from "gsap"

const Loader = () => {
  const Ltl = gsap.timeline()

  Ltl.to(
    ".logo",
    {
      opacity: 0,
      ease: Expo.easeOut,
      duration: 2,
    },
    ""
  ).to(
    ".loader",
    {
      delay: 1,
      width: "0%",
      ease: Expo.easeOut,
      duration: 3,
    },
    ""
  )

  return (
    <div className="fixed inset-y-0 right-0 w-full z-50">
      <div className="absolute inset-y-0 left-0 w-[51%] bg-primary loader"></div>
      <div className="absolute inset-y-0 right-0 w-[51%] bg-primary loader"></div>
      <div className="logo absolute inset-0 flex items-center justify-center h-screen bg-gray-900 bg-opacity-30 backdrop-blur-sm">
        {/* Shape of loader (square rotated 45 deg) */}
        <div className="w-40 h-40 transform rotate-45 flex items-center justify-center overflow-hidden shadow-lg shadow-secondary border-2">
          <div className="relative w-full h-full -rotate-45 flex items-center justify-center">
            <img
              src={logo} // Replace with your image path
              alt="Loader"
              className="w-32 h-30 object-cover absolute"
            />
            <svg
              className="w-7 h-7 mt-28 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>

            {/* Loading animation (white water filling effect) */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
