import house1 from "../../../asserts/heroImage1.jpg"
import house2 from "../../../asserts/heroImage2.png"
import house3 from "../../../asserts/heroImage3.JPG"
import house4 from "../../../asserts/heroImage4.jpg"
import house5 from "../../../asserts/heroImage5.JPG"
import house6 from "../../../asserts/heroImage6.JPG"

export const leadSection = [
  {
    id: "1",
    text: {
      mainText: "The bathroom is a sanctuary for the day ahead.",
      subText: "We specialize in designing and building bathrooms.",
    },
    imageSrc: house1,
  },
  {
    id: "2",
    text: {
      mainText: "Our home is your sanctuary.",
      subText: "We specialize in building homes that are both stylish and livable.",
    },
    imageSrc: house2,
  },
  {
    id: "3",
    text: {
      mainText: "The entrance of your house is the first impression you make on guests.",
      subText: "We design and build house entrances that are both stylish and welcoming.",
    },
    imageSrc: house3,
  },
  {
    id: "4",
    text: {
      mainText: "Your house lobby is the first impression you make on guests. Make it a grand one.",
      subText: "We'll take the time to build you a home that's perfect for you.",
    },
    imageSrc: house4,
  },
  {
    id: "5",
    text: {
      mainText: "Your kitchen is the heart of your home.",
      subText: "We specialize in designing and building kitchens that are both stylish and functional.",
    },
    imageSrc: house5,
  },
  {
    id: "6",
    text: {
      mainText: "Home is where you can be yourself.",
      subText: "Let us help you create a home where you can truly relax and be yourself.",
    },
    imageSrc: house6,
  },
]
