import React, { useEffect } from "react"
import gsap, { Expo } from "gsap"
import { useLocation } from "react-router-dom"

const Transition = () => {
  const { pathname } = useLocation()
  const timeline = gsap.timeline()

  useEffect(() => {
    timeline
      .to(
        ".transition",
        {
          width: "100%",
          ease: Expo.easeInOut,
          duration: 0.03,
        },
        "Flag_name1"
      )
      .to(
        ".transition",
        {
          width: "0%",
          right: "100%",
          ease: Expo.easeInOut,
          delay: 1,
          duration: 1,
        },
        "Flag_name2"
      )
      .to(
        ".transition",
        {
          width: "-10%",
          right: "0%",
          ease: Expo.easeInOut,
          duration: 0,
        },
        "Flag_name3"
      ) //next
      .to(
        ".transition_next",
        {
          width: "100%",
          ease: Expo.easeInOut,
          // duration: 0,
        },
        "Flag_name1"
      )
      .to(
        ".transition_next",
        {
          width: "0%",
          right: "100%",
          ease: Expo.easeInOut,
          delay: 1.2,
          duration: 1,
        },
        "Flag_name2"
      )
      .to(
        ".transition_next",
        {
          width: "-10%",
          right: "0%",
          ease: Expo.easeInOut,
          duration: 0,
        },
        "Flag_name3"
      )
  }, [pathname])

  return (
    <>
      <div className="fixed inset-0 w-0 z-40 bg-primary bg-opacity-60 transition_next"></div>
      <div className="fixed inset-y-0 right-0 w-0 z-40 bg-primary transition"></div>
    </>
  )
}

export default Transition
