import React from "react"

const TermAndConditions = () => {
  return (
    <>
      <div className="pt-36 pb-24 md:px-10 px-4 flex flex-col items-center">
        <span className="font-medium text-4xl">Term and Conditions</span>
        <div className="flex flex-col md:flex-row justify-center w-full mt-20">
          {/* <p className="font-bold md:text-4xl text-xl">Guarantee</p> */}
          <div className="md:w-[80%] text-justify mt-9 md:mt-0 md:text-base text-sm">
            <span>Guarantee</span>
            <br />
            <br />
            <span>
              Since its inception in 1976, MANN HOMES Pty Ltd has dedicated
              itself to crafting exceptional homes for Australian families. We
              remain steadfast in our commitment to delivering excellence in
              design, construction, quality, and customer satisfaction. Over the
              years, our relentless dedication has elevated us to become one of
              Australia's foremost home builders.
              <br />
              <br />
              Our Structural Guarantee is provided in addition to any existing
              guarantee, statutory insurance, or homebuilders liability scheme
              applicable in Australia and is subject to the terms outlined
              below. The Lifetime Structural Guarantee is specifically
              applicable to the individuals named in the building contract and
              is non-transferable to subsequent homeowners. This guarantee is
              effective for all new home building contracts executed after 1
              January 2019.
              <br />
              <br />
              In this document, references to "We," "Our," or "Us" pertain to
              MANN HOMES Pty Ltd, except in cases where your home is constructed
              in Queensland, where it refers to MANN HOMES QLD Pty Ltd.
              <br />
              <br />
              References to "You" or "Your" pertain to the owners identified in
              the building contract.
            </span>
            <br />
            <br />
            <span>MANN HOMES' Responsibilities</span>
            <br />
            <br />
            <span>
              We commit to rectify, at our expense, any structural failure
              within the following components:
              <br />
              <br />
              Foundation systems, concrete, and/or strip footing, in accordance
              with the Building Code of Australia and all relevant Australian
              Standards, including AS 2870, Appendix B. Structural failure is
              defined as 'Damage Category 3' or higher (AS 2870) for a
              structural failure to exist.
              <br />
              <br />
              Structural timbers and steel within wall or roof framing, where
              structural failure is defined in accordance with the Building Code
              of Australia and all relevant Australian Standards, including AS
              1684.
              <br />
              <br />
              Load-bearing brickwork, where structural failure is defined in
              accordance with the Building Code of Australia and all relevant
              Australian Standards, including AS 4773.
            </span>
            <br />
            <br />
            <span>Your Responsibilities</span>
            <br />
            <br />
            <span>
              You are responsible for maintaining your home in accordance with
              the documents provided to you as part of your building contract
              documentation and the documents listed in the table below (as
              applicable in your State), along with any revisions to those
              documents issued over time. Failure to do so will void your
              Lifetime Structural Guarantee.
            </span>
            <br />
            <br />
            <span>State-Specific Requirements:</span>
            <br />
            <br />
            <span>
              Victoria (VIC):
              <br />
              <br />
              CSIRO guide - Foundation Maintenance and Footing Performance: A
              Homeowner’s Guide
              <br />
              <br />
              New Home Guide - Guide to Planning Landscaping and Maintenance of
              Foundations document (provided to you at settlement of your new
              home)
            </span>
            <br />
            <br />
            <span>Provider of the Guarantee (VIC):</span>
            <br />
            <br />
            <span>
              MANN HOMES Pty Ltd
              <br />
              <br />
              ABN: 93629804360
              <br />
              <br />
              Address: PO Box 2034 Melton South VIC 3338
              <br />
              <br />
              Phone: +61 0434 055 559
              <br />
              <br />
              Email: Info@mannhomes.com.au
            </span>
            <br />
            <br />
            <span>
              Eligibility for a Claim under the Lifetime Structural Guarantee
            </span>
            <br />
            <br />
            <span>
              The owners identified in the building contract for any home
              constructed by us are eligible to make a claim under the Lifetime
              Structural Guarantee. Claims must be made within the lifetime of
              the owners named in the building contract. In cases where the
              building contract owner is a company, the guarantee period extends
              to 50 years from the issuance of the certificate of occupancy. The
              Lifetime Structural Guarantee is neither transferable nor
              assignable. It does not cover Everyone Homes, a division of MANN
              HOMES.
            </span>
            <br />
            <br />
            <span>Claims Process</span>
            <br />
            <br />
            <span>
              To initiate a claim under this guarantee, you must submit a
              written request to us via post or email at the address provided in
              this document.
              <br />
              <br />
              Upon receiving your claim:
              <br />
              <br />
              We will evaluate your request and inspect your property to assess
              if a structural failure meeting the guarantee's requirements
              exists.
              <br />
              <br />
              We will communicate our assessment results to you in writing.
              <br />
              <br />
              If the structural failure aligns with the guarantee's criteria, we
              will commence rectification works at our expense within a
              reasonable timeframe.
              <br />
              <br />
              Our products are accompanied by guarantees under the Australian
              Consumer Law that cannot be excluded. You are entitled to a
              replacement or refund for a major failure and compensation for any
              other reasonably foreseeable loss or damage. If the goods fail to
              be of acceptable quality and the failure does not amount to a
              major failure, you are entitled to have the goods repaired or
              replaced.
              <br />
              <br />
              For this guarantee, 'goods' pertains to the structural elements of
              a home, as detailed under the 'MANN HOMES' Responsibilities'
              section.
              <br />
              <br />
              If we are responsible and capable of repair, we will undertake the
              necessary repairs. In cases where repair is not feasible, we will
              replace the components as needed to rectify the structural
              failure.
              <br />
              <br />
              Should we determine that there is no structural failure or that
              the structural failure does not meet the guarantee's requirements
              (e.g., due to causes listed in the exclusion section of this
              document), you will be responsible for all rectification costs.
            </span>
            <br />
            <br />
            <span>Website Terms and Conditions</span>
            <br />
            <br />
            <span>Copyright</span>
            <br />
            <br />
            <span>
              © 2017 MANN HOMES Pty Ltd (ABN 93629804360). Copyright for this
              website is protected under the Copyright Act 1968 (Cth) and is
              owned by MANN HOMES Pty Ltd unless otherwise indicated. All rights
              are reserved. You may access this website for personal use only.
              You may print information from this website for personal use,
              unless such printing is expressly prohibited. Commercial use or
              on-selling of information obtained from this website is strictly
              prohibited unless permitted under the Copyright Act 1968 (Cth) or
              other applicable laws, or expressly authorized by these terms and
              conditions.
            </span>
            <br />
            <br />
            <span>Links to Other Websites</span>
            <br />
            <br />
            <span>
              This website may contain links to other websites beyond MANN HOMES
              Pty Ltd's control, provided for user convenience. These links do
              not constitute official endorsement or approval of any linked
              website, product, organization, or service. MANN HOMES Pty Ltd is
              not responsible for the content or privacy practices of any linked
              website.
            </span>
            <br />
            <br />
            <span>Disclaimer of Liability</span>
            <br />
            <br />
            <span>
              While every effort has been made to ensure the accuracy of
              information on this website, no warranty can be given by MANN
              HOMES Pty Ltd or its agents regarding the accuracy, adequacy, or
              completeness of any information presented. Intending purchasers
              should conduct their own inspections and inquiries. Photographs
              and artist impressions are for presentation purposes only and are
              indicative. To the extent permitted by law, any condition or
              warranty implied into these terms and conditions is excluded. MANN
              HOMES Pty Ltd assumes no responsibility for any loss, damage,
              cost, or expense (whether direct or indirect) incurred as a result
              of your use of this website or any linked website or reliance on
              any information accessed through this website. This disclaimer
              does not attempt to exclude liability arising under statute or law
              if such liability cannot be lawfully excluded.
              <br />
              <br />
              All information on the site is subject to change without notice.
            </span>
            <br />
            <br />
            <span>Intellectual Property Policy</span>
            <br />
            <br />
            <span>
              MANN HOMES places significant effort into designing exceptional
              Australian homes. We take our intellectual property rights
              seriously, not only to protect our investment but also the
              investment of our valued customers. All plans, designs,
              illustrations, photos, images, or similar materials produced by
              MANN HOMES, whether in print or represented in actual homes, are
              owned by MANN HOMES. Unauthorized use, in whole or in part, is a
              breach of MANN HOMES' copyright, unless expressly authorized.
              <br />
              <br />
              We authorize the use of our intellectual property primarily
              through building contracts with Metricon. We will pursue legal
              avenues to protect MANN HOMES and its customers in the event of
              any breach of our intellectual property rights.
              <br />
              <br />
              For further information, please contact info@mannhomes.com.au.
            </span>
            <br />
            <br />
            <span>Home & Land</span>
            <br />
            <br />
            <span>Non-Fixed Price Home & Land Conditions</span>
            <br />
            <br />
            <span>
              All images and illustrations serve as guides only and may depict
              non-standard or upgrade options not included in the package price.
              <br />
              <br />
              Land is not supplied by MANN HOMES; land developer terms and
              conditions apply.
              <br />
              <br />
              Home and Land packages are only available for the specific lot and
              home advertised.
              <br />
              <br />
              Packages do not cover site costs incurred due to rock found on any
              lots advertised.
              <br />
              <br />
              Determining the house siting on the lot is at MANN HOMES'
              discretion.
              <br />
              <br />
              Packages may change due to variations in inclusions or
              specifications required by the purchaser, local authorities, or
              applicable laws.
              <br />
              <br />
              Packages do not include stamp duty, legal fees, or any other costs
              associated with the purchase.
              <br />
              <br />
              Unless otherwise noted, packages do not cover bushfire, acoustic,
              or N3 requirements.
              <br />
              <br />
              Home and Land packages require covenant and council approval,
              subject to approval before entering into a contract with MANN
              HOMES.
              <br />
              <br />
              MANN HOMES is responsible for the sale of the home only.
              <br />
              <br />
              Customers must enter into a separate contract for the land
              component of the package with the relevant land developer.
              <br />
              <br />
              Home and Land packages are available to customers who make a
              deposit before or on the advertised timeframe, or at the
              discretion of MANN HOMES, and who enter into a final home building
              contract with MANN HOMES before or on the advertised timeframe.
              <br />
              <br />
              Final costs, dimensions, and inclusions may vary and may be
              changed by MANN HOMES without notice.
              <br />
              <br />
              MANN HOMES reserves the right to withdraw any Home & Land Package
              without notice.
              <br />
              <br />
              MANN HOMES reserves the right to withdraw any Home & Land Package
              if the land or home becomes unavailable or sold.
            </span>
            <br />
            <br />
            <span>Fixed Price Home & Land Conditions</span>
            <br />
            <br />
            <span>
              All images and illustrations serve as guides only and may depict
              non-standard or upgrade options not included in the package price.
              <br />
              <br />
              Land is not supplied by MANN HOMES; land developer terms and
              conditions apply.
              <br />
              <br />
              Home and Land packages are only available for the specific lot and
              home advertised.
              <br />
              <br />
              Determining the house siting on the lot is at MANN HOMES'
              discretion.
              <br />
              <br />
              Packages may change due to variations in inclusions or
              specifications required by the purchaser.
              <br />
              <br />
              Packages do not include stamp duty, legal fees, or any other costs
              associated with the purchase.
              <br />
              <br />
              Unless otherwise noted, packages do not cover bushfire, acoustic,
              or N3 requirements.
              <br />
              <br />
              Home and Land packages require covenant and council approval,
              subject to approval before entering into a contract with MANN
              HOMES.
              <br />
              <br />
              MANN HOMES is responsible for the sale of the home only.
              <br />
              <br />
              Customers must enter into a separate contract for the land
              component of the package with the relevant land developer.
              <br />
              <br />
              Home and Land packages are available to customers who make a
              deposit before or on the advertised timeframe, or at the
              discretion of MANN HOMES, and who enter into a final home building
              contract with MANN HOMES before or on the advertised timeframe.
              <br />
              <br />
              Final costs, dimensions, and inclusions may vary and may be
              changed by MANN HOMES without notice.
              <br />
              <br />
              MANN HOMES reserves the right to withdraw any Home & Land Package
              without notice.
              <br />
              <br />
              MANN HOMES reserves the right to withdraw any Home & Land Package
              if the land or home becomes unavailable or sold.
            </span>
            <br />
            <br />
            <span>General Limitations:</span>
            <br />
            <br />
            <span>
              Home and Land packages are not eligible for advertised promotions
              when a home is purchased outside any package arrangement.
            </span>
            <br />
            <br />
            <span>Verification Requirements:</span>
            <br />
            <br />
            <span>Builder's Licenses (VIC): CBU-76220</span>
            <br />
            <br />
            <span>
              MANN HOMES Pty Ltd ABN: 93629804360
              <br />
              <br />
              Address: PO Box 2034 Melton South VIC 3338
              <br />
              <br />
              Phone: +61 0434 055 559
              <br />
              <br />
              Email: Info@mannhomes.com.au
            </span>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

export default TermAndConditions
