import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import rp1 from "../../../../asserts/recProject/RP1.jpg"
import rp2 from "../../../../asserts/recProject/RP2.jpg"
import rp3 from "../../../../asserts/recProject/RP3.jpg"
import rp4 from "../../../../asserts/recProject/RP4.jpg"
import rp5 from "../../../../asserts/recProject/RP5.jpg"

const RecentProjectSec = () => {
  const navigate = useNavigate()

  useEffect(() => {
    gsap.to(".recProject_first", {
      scrollTrigger: {
        trigger: ".recProject_first",
        start: "top 50%", // Adjust the trigger start position
        end: "bottom 100%", // Adjust the trigger end position
        scrub: 5, // Enables smooth scrolling effect
        scroller: "body",
      },
      width: "0%",
      height: "0%",
      duration: 4,
    })
    gsap.to(".recProject_second", {
      scrollTrigger: {
        trigger: ".recProject_second",
        start: "top 60%", // Adjust the trigger start position
        end: "bottom 100%", // Adjust the trigger end position
        scrub: 3, // Enables smooth scrolling effect
        scroller: "body",
      },
      width: "0%",
    })
  }, [])

  return (
    <React.Fragment>
      <div className="flex justify-center mt-40 flex-col items-center">
        <span className="text-3xl font-semibold ">Recent Projects</span>
        <div className="flex flex-col md:flex-row mt-16 w-full md:justify-between ">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:gap-4 gap-2 overflow-hidden">
            <div className="relative h-full">
              <img
                src={rp1}
                alt=""
                loading="lazy"
                className="1 object-cover w-[20rem] hidden md:block"
              />
            </div>
            <div className="relative h-[17rem] w-[24rem]">
              <img
                src={rp2}
                alt=""
                loading="lazy"
                className="2 object- cover h-full"
              />
            </div>
            <div className="relative">
              <img
                src={rp3}
                alt=""
                loading="lazy"
                className="3 object-cover w-[25rem]"
              />
            </div>
            <div className="relative h-[16rem] w-[26rem]">
              <img
                src={rp4}
                alt=""
                loading="lazy"
                className="4 object-cover h-[16rem]"
              />
            </div>
          </div>
          <div className="relative mt-2 md:mt-0">
            <img
              src={rp5}
              alt=""
              loading="lazy"
              className="5 object-contain h-full w-[35rem]"
            />
          </div>
        </div>
        <button
          className="mt-16 py-5 px-20 bg-primary text-black"
          onClick={() => {
            navigate("/projects")
          }}
        >
          VIEW ALL
        </button>
      </div>
    </React.Fragment>
  )
}

export default RecentProjectSec
