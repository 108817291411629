import React from "react"
import { useNavigate } from "react-router-dom"
import p1 from "../../../asserts/projects/download1.jpg"
import p2 from "../../../asserts/projects/download2.jpg"
import p3 from "../../../asserts/projects/download3.jpg"
import p4 from "../../../asserts/projects/download4.jpg"
import p5 from "../../../asserts/projects/download5.jpg"
import p6 from "../../../asserts/projects/download6.jpg"
import p7 from "../../../asserts/projects/download7.jpg"
import p8 from "../../../asserts/projects/download8.jpg"
import p9 from "../../../asserts/projects/download9.jpg"
import p10 from "../../../asserts/projects/download10.jpg"
import p11 from "../../../asserts/projects/download11.jpg"

const Projects = () => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="flex justify-center w-full pt-16">
        <div className="mt-24 flex flex-col items-center">
          <span className="text-4xl font-bold">Projects</span>
          <div className="my-20 flex flex-wrap items-center justify-center space-x-2 space-y-2 border-t-2 border-primary">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download1.jpg?alt=media&token=ed4a9c70-4fe8-4b81-92b4-a339eabaf515"
              alt=""
              loading="lazy"
              className="6 object-cover w-[28rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download2.jpg?alt=media&token=66fc95ce-31bc-4a4d-96cc-71cd597cbf94"
              alt=""
              loading="lazy"
              className="7 object-cover w-[28rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download3.jpg?alt=media&token=83b638c1-150a-47b8-84c8-eb1910c78580"
              alt=""
              loading="lazy"
              className="8 object-cover w-[28rem]"
            />


            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download4.jpg?alt=media&token=1dc3aeac-361f-43e4-8425-fa8ce3286433"
              alt=""
              loading="lazy"
              className="9 object-cover w-[25rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download5.jpg?alt=media&token=ddbecb5a-67ff-4ab4-877c-d8ab9452f4ce"
              alt=""
              loading="lazy"
              className="10 object-cover w-[25rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download6.jpg?alt=media&token=61be2e45-90a3-4d3d-a934-403cae1d1a8a"
              alt=""
              loading="lazy"
              className="11 object-cover w-[25rem]"
            />


            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download7.jpg?alt=media&token=9f27b45c-5b1b-46e3-b3ae-5103fc6e8070"
              alt=""
              loading="lazy"
              className="12 object-cover w-[25rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download8.jpg?alt=media&token=7b3c118e-6b9a-487f-a9ea-1c7cdfe6f24a"
              alt=""
              loading="lazy"
              className="13 object-cover w-[25rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download9.jpg?alt=media&token=a7993b55-e094-4dd7-91dc-70d354e36dee"
              alt=""
              loading="lazy"
              className="14 object-cover w-[25rem]"
            />


            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download10.jpg?alt=media&token=4df3c8c3-afed-472d-a4ed-43ea5477c27f"
              alt=""
              loading="lazy"
              className="15 object-cover w-[25rem]"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c3cee.appspot.com/o/download11.jpg?alt=media&token=928bd3d7-4f84-4d39-8822-34444b302393"
              alt=""
              loading="lazy"
              className="16 md:object-none object-cover w-[25rem]"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Projects
