import React from "react"
import service1 from "../../../asserts/services/service1.png"
import service2 from "../../../asserts/services/service2.png"

const Services = () => {
  return (
    <React.Fragment>
      <div className="pt-36 pb-24 px-10 flex flex-col items-center">
        <span className="font-bold text-4xl">Services</span>
        <div className="mt-10 grid lg:grid-cols-4 grid-rows-4 lg:grid-rows-1 w-full lg:h-[420px] border-2 border-primary">
          <div className="bg-primary flex justify-center items-center text-4xl font-bold pb-20 pr-6 h-[20rem] sm:h-full">
            Our
            <br />
            Services
          </div>
          <div className="flex justify-center items-center flex-col space-y-4 md:border-r-2 border-primary">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Custom
              <br />
              Homes
            </span>
            <span className="w-60">
              we specializes in crafting personalized custom homes tailored to
              your unique vision and lifestyle. Collaborate with us to design a
              one-of-a-kind residence that reflects your tastes and needs,
              ensuring a dream home that's uniquely yours. Experience the
              freedom of customization yours home.
            </span>
          </div>
          <div className="flex justify-center items-center flex-col space-y-4 border-y-2 md:border-y-0 border-primary">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Renovation
            </span>
            <span className="w-60">
              Transform your existing space into a personalized sanctuary with
              renovation expertise. Our team rejuvenates homes with precision,
              enhancing functionality and aesthetics. Whether it's a room
              makeover or a full house transformation, trust us to revitalize
              your living spaces with unmatched craftsmanship and attention to
              detail.
            </span>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={service1}
              alt=""
              loading="lazy"
              className="object-cover h-full"
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-4 grid-rows-4 lg:grid-rows-1 w-full lg:h-[420px] border-2 border-primary">
          <div className="flex justify-center items-center">
            <img
              src={service2}
              loading="lazy"
              alt=""
              className="object-cover h-full"
            />
          </div>
          <div className="flex justify-center items-center flex-col space-y-4 md:border-r-2 border-primary">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Knock-down rebuild
            </span>
            <span className="w-60">
              Embrace a fresh start by demolishing and rebuilding your dream
              home. Enjoy the advantages of a new layout and modern design while
              preserving your cherished location. Elevate your lifestyle with a
              knock-down rebuild.
            </span>
          </div>
          <div className="flex justify-center items-center flex-col space-y-4 md:border-r-2 border-y-2 md:border-y-0 border-primary">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Planning Permit
            </span>
            <span className="w-60">
              Navigate the complexities of obtaining a planning permit
              seamlessly. We streamline the process, ensuring compliance with
              regulations and facilitating your construction project's smooth
              progression from concept to reality.
            </span>
          </div>
          <div className="flex justify-center items-center flex-col space-y-4">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Expertise in
              <br />
              Civil engineer
            </span>
            <span className="w-60">
              Our team boasts exceptional expertise in civil engineering,
              ensuring precise design, analysis, and construction of
              infrastructure projects. We deliver innovative solutions, from
              road networks to drainage systems, meeting engineering challenges
              with excellence and precision.
            </span>
          </div>
        </div>

        <div className="grid lg:grid-cols-4 grid-rows-4 lg:grid-rows-1 w-full lg:h-[420px] border-2 border-primary">
          <div className="flex justify-center items-center text-4xl font-bold pb-20 pr-6 h-[16rem] sm:h-full">
            Our Expertise
          </div>
          <div className="flex justify-center items-center flex-col space-y-4 md:border-x-2 md:border-y-0 border-y-2 border-primary">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Pre-Construction
            </span>
            <span className="w-60">
              Design-Build
              <br />
              Scheduling
              <br />
              Estimating & Budgeting
            </span>
          </div>
          <div className="flex justify-center items-center flex-col space-y-4 md:border-r-2 border-b-2 md:border-b-0 border-primary">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Construction
            </span>
            <span className="w-60">
              Quality Control
              <br />
              Safety Management
              <br />
              Subcontractor
              <br />
              Management
            </span>
          </div>
          <div className="flex justify-center items-center flex-col space-y-4">
            <span className="text-2xl text-primary font-semibold text-left w-60">
              Post-Construction
            </span>
            <span className="w-60">
              Certificate of Occupancy
              <br />
              As-built documentation
              <br />
              Warranty
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Services
