import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import SLoader from "../../../../asserts/skeleton.gif"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import _ from "lodash"
import service4 from "../../../../asserts/services/service4.png"

gsap.registerPlugin(ScrollTrigger)

const AboutUsSec = () => {
  const navigate = useNavigate()

  const AboutText =
    "Welcome to Mann Homes - your ultimate destination for transforming your dream of a perfect home into reality. From the initial stages of planning and permit acquisition, right through to the grand moment of completion, we orchestrate every facet of the journey. Our distinguished team features accredited civil engineers, adept builders, and demolition specialists, all working in harmony to bring your vision to life. With a rich legacy spanning over a decade in the construction realm, we proudly embody a legacy of expertise and distinction in the field. Your dream home awaits its masterpiece transformation with Mann Homes"
  const mewText = _.truncate(AboutText, {
    length: 400,
  })

  useEffect(() => {
    gsap.to(".about_first", {
      scrollTrigger: {
        trigger: ".about_first",
        start: "top 50%", // Adjust the trigger start position
        end: "bottom 100%", // Adjust the trigger end position
        scrub: 5, // Enables smooth scrolling effect
        scroller: "body",
      },
      left: "100%",
      duration: 4,
    })
    gsap.to(".about_second", {
      scrollTrigger: {
        trigger: ".about_second",
        start: "top 60%", // Adjust the trigger start position
        end: "bottom 100%", // Adjust the trigger end position
        scrub: 3, // Enables smooth scrolling effect
        scroller: "body",
      },
      left: "100%",
    })
  }, [])
  return (
    <React.Fragment>
      <div className="border-2 border-primary">
        <div className="grid grid-rows-2 md:grid-rows-none md:grid-cols-2 grid-flow-col md:grid-flow-row">
          <div className="h-full order-1 md:order-none flex flex-col items-center justify-center sm:py-5 py-10">
            <span className="text-4xl font-bold md:-ml-[60px]">
              About Us
            </span>
            <p className="sm:py-5 py-10 w-4/5">
              {mewText}
              <button className="text-primary hover:cursor-pointer underline ml-1 hover:text-secondary" onClick={()=>{navigate("/about-us")}}>
                Read More
              </button>
            </p>
          </div>
          <div className="relative order-2 md:order-none">
          {/* <img src={SLoader} className="w-[50rem] h-[26.5rem] object-cover -z-10 absolute" alt="" /> */}
            <img
              src={service4}
              alt="services"
              className="object-cover h-[30rem] w-full"
              loading="lazy"
            />
            <div className="bg-primary absolute inset-y-0 right-0 left-0 about_second"></div>
            <div className="bg-primary bg-opacity-70 absolute inset-y-0 right-0 left-0 about_first"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutUsSec
