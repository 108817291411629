export const PropertiesList = [
  {
    status: "for sale",
    title: "main",
    desc: "Certainly, here are rough revenue estimates based on the additional customers you might acquire through the strategies mentioned- Additional 100-200 customers per month could potentially generate an extra revenue of Rs 50,000 to Rs 100,000 per month, assuming an average spend per customer of Rs 500.2. **Email Marketing and Local Partnerships**- If you gain 10-25 new customers per email blast and send out, let's say, two email campaigns per month, that could result in an additional revenue of Rs 10,000 to Rs 25,000 per month.- Local partnerships, bringing in 20-50 new customers per month, might contribute an extra revenue of Rs 10,000 to Rs 25,000 per month.3. **Website Improvement and Online Ordering**:- Attracting an additional 50-100 customers per month through online ordering could potentially generate an extra revenue of Rs 25,000 to Rs 50,000 per month. - If online-exclusive discounts attract 20-40 new customers per month, this might add another Rs 10,000 to Rs 20,000 per month in revenue.These are rough estimates and actual revenue figures can vary based on factors like average order value, menu prices, and customer retention. Regularly monitoring your financials and adjusting your strategies accordingly will help you achieve your revenue goal of Rs 200,000 per month.",
    key_points: "specifications",
    land_area: "11 Biswa",
    transaction_type: "New Property",
    property_type: "Commercial Lands /Inst. Land",
    landmark: {
      hospital: "5 km",
      airport: "10 km",
      school: "2 km",
      atm: "1 km",
      bank: "10 km",
      bus_stop: "1 km",
      metro: "2 km",
    },
    img: "https://th.bing.com/th/id/OIP.Iep8WRR2L3oQnBsIA4akMQHaE8?pid=ImgDet&rs=1",
  },
  {
    status: "sold out",
    title: "hikkln",
    desc: "vdasvddv",
    key_points: "vdssda",
    transaction_type: "vdsa",
    property_type: "vsdasva",
    landmark: {
      hospital: "15 km",
      airport: "100 km",
      school: "5 km",
      atm: "10 km",
      bank: "6 km",
      bus_stop: "2 km",
      metro: "4 km",
    },
    img: "https://th.bing.com/th/id/OIP.Iep8WRR2L3oQnBsIA4akMQHaE8?pid=ImgDet&rs=1",
  },
]
