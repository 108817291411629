import React from "react"

const TestimonialSec = () => {
  const Reviews = [
    {
      id: "1",
      name: "Raman Dhillon",
      text: "From the moment we met our builder, we knew we were in good hands. Their track record and portfolio of work spoke volumes about their skill and dedication. We're thrilled with the results.",
    },
    {
      id: "2",
      name: "Taminder Rai",
      text: "Working with Mann Homes was a fantastic experience. They brought years of knowledge to the table, helping us make informed decisions and ensuring the project ran smoothly from start to finish.",
    },
    {
      id: "3",
      name: "Spenser Strider",
      text: "I can confidently say that Mann Homes is a builder that combines experience, professionalism, and a genuine passion for creating dream homes. I am immensely grateful to them for building the fantastic home I now cherish. If you're seeking a builder who will transform your vision into a reality, I wholeheartedly recommend Mann Homes.",
    },
    {
      id: "4",
      name: "Paresh Patel",
      text: "We were impressed by Mann Homes’s extensive experience and their ability to turn our vision into reality. They navigated the complexities of our project with ease, and the results speak for themselves.",
    },
  ]
  return (
    <React.Fragment>
      <div className="mt-20 flex flex-col md:flex-row md:h-[480px] border-2 border-primary">
        <div className="bg-primary md:h-full h-[320px] md:w-[400px] flex justify-center items-center text-4xl font-semibold">
          <span>Testimonials</span>
        </div>
        <div className="flex justify-between md:flex-1 md:overflow-x-scroll cursor-grab scrollbar-hide overflow-y-scroll h-[360px] md:h-full flex-col md:flex-row">
          {Reviews.map((items,index) => {
            return (
              <div key={"testimonial"+index} className="flex-1 flex justify-center items-center py-32 flex-col md:border-r-2 border-r-0 border-b-2 border-primary">
                <p className="w-[22rem] px-6 text-justify flex justify-center mb-8">
                  "{items.text}"
                </p>
                <span className="capitalize text-lg">{items.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default TestimonialSec
